import api from './utilBackoffice';

const controller = 'PessoaPJ';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  GetByCodigoPessoa: dto => {
    return api.Axios.GetParams(`${url}/${dto.CodigoPessoa}`);
  },
  Update: function (codigoPessoa, dto) {
    return api.Axios.Put(url, codigoPessoa, dto);
  }
});
