import api from './utilBackoffice';

const controller = 'TipoContrato';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => {
    return api.Axios.GetParams(url, dto);
  },
  ListarByRedeLoja: dto => {
    return api.Axios.GetParams(url + '/ListarByRedeLoja', dto);
  },
  ListarCodigos: dto => {
    return api.Axios.GetParams(url + '/ListarCodigos', dto);
  },
  Create: dto => {
    return api.Axios.Post(url, dto);
  },
  CreateMovPendente: dto => {
    return api.Axios.Post(url + '/InsertContratoPendente', dto);
  },
  Delete: codigo => {
    return api.Axios.Delete(url, codigo);
  },
  Edit: dto => {
    return api.Axios.Post(url + '/Update', dto);
  }
});
