import api from './utilBackoffice';

const controller = 'EmpresaUsuario';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => {
    return api.Axios.GetParams(url, dto);
  },
  Editar: (epCodigo, dto) => {
    return api.Axios.Put(url, dto, epCodigo);
  }
});
