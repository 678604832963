import api from './utilBackoffice';

const controller = 'TagsArquivo';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  ListarCli: dto => {
    return api.Axios.GetParams(url + '/Cli', dto);
  },
  ListarOp: dto => {
    return api.Axios.GetParams(url + '/Op', dto);
  },
  ListarVenc: dto => {
    return api.Axios.GetParams(url + '/Venc', dto);
  },
  ListarInf: dto => {
    return api.Axios.GetParams(url + '/Inf', dto);
  },
  ListarAgreg: dto => {
    return api.Axios.GetParams(url + '/Agreg', dto);
  },
  ListarAgregVcto: dto => {
    return api.Axios.GetParams(url + '/AgregVcto', dto);
  },
  UpdateVenc: dto => {
    return api.Axios.Post(url + '/UpdateVenc', dto);
  },
  UpdateOp: dto => {
    return api.Axios.Post(url + '/UpdateOp', dto);
  },
  UpdateCli: dto => {
    return api.Axios.Post(url + '/UpdateCli', dto);
  },
  UpdateInf: dto => {
    return api.Axios.Post(url + '/UpdateInf', dto);
  },
  UpdateAgreg: dto => {
    return api.Axios.Post(url + '/UpdateAgreg', dto);
  },
  UpdateAgregVcto: dto => {
    return api.Axios.Post(url + '/UpdateAgregVcto', dto);
  },
  DeleteOp: dto => {
    return api.Axios.Post(url + '/DeleteOp', dto);
  },
  DeleteCli: dto => {
    return api.Axios.Post(url + '/DeleteCli', dto);
  },
  DeleteAgregVcto: dto => {
    return api.Axios.Post(url + '/DeleteAgregVcto', dto);
  },
  DeleteAgreg: dto => {
    return api.Axios.Post(url + '/DeleteAgreg', dto);
  }
});
