import api from '../utilBackoffice';

const controller = 'RemessaFila';
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => {
    return api.Axios.GetParams(url, dto);
  },
  Create: dto => {
    return api.Axios.Post(url, dto);
  },
  Delete: idFila => {
    return api.Axios.Delete(url, idFila);
  },
  Reprocessar: dto => {
    return api.Axios.Post(url + '/Reprocessamento', dto);
  }
});
