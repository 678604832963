import api from '../utilBackoffice';

const controller = 'BackOfficeUsuarioGrupoUsuario';
const url = process.env.VUE_APP_AUTH_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar(params) {
    return api.Axios.GetParams(url, params);
  },
  ListarGruposBMP(params) {
    return api.Axios.GetParams(url + '/ListarGruposBMP', params);
  },
  SalvarGruposUsuario(dto) {
    return api.Axios.Post(url + '/SalvarGruposUsuario', dto);
  }
});
