import api from './utilBackoffice';

const controller = 'MovimentoPendente';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => {
    return api.Axios.GetParams(url, dto);
  },
  Count: () => {
    return api.Axios.GetParams(url + '/Count');
  },
  Edit: dto => {
    return api.Axios.Post(url + '/Update', dto);
  },
  Reprocessa: dto => {
    return api.Axios.Post(url + '/Reprocessa', dto);
  },
  ReprocessarTudo: dto => {
    return api.Axios.GetParams(url + '/ReprocessarTudo', dto);
  },
  ReprocessaPrejuizo: dto => {
    return api.Axios.Post(url + '/ReprocessaPrejuizo', dto);
  }
});
