import api from './utilBackoffice';

const controller = 'CaracteristicaEspecial';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => {
    return api.Axios.GetParams(url, dto);
  },
  Create: dto => {
    return api.Axios.Post(url, dto);
  },
  Update: (id, dto) => {
    return api.Axios.Put(url, id, dto);
  },
  Delete: id => {
    return api.Axios.Delete(url, id);
  }
});
