import api from './utilBackoffice';

const controller = 'SysUsuario';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  ListarFuncionarioBMPDisponivel: dto => {
    return api.Axios.GetParams(url + '/ListarFuncionarioBMPDisponivel', dto);
  },
  ListarFuncionarioBMPExcept: dto => {
    return api.Axios.GetParams(url + '/ListarFuncionarioBMPExcept', dto);
  },
  ListarTodosUsuarios: dto => {
    return api.Axios.GetParams(url + '/ListarTodosUsuario', dto);
  }
});
