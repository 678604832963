import api from '../utilBackoffice';

const controller = 'BackOfficeUsuario';
const url = process.env.VUE_APP_AUTH_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar(dto) {
    return api.Axios.GetParams(url, dto);
  },
  LocalizarRegistro(id) {
    return api.Axios.Get(url, id);
  },
  Post(dto) {
    return api.Axios.PostForm(url, dto);
  },
  Put(id, dto) {
    return api.Axios.Put(url, id, dto);
  },
  Delete(id) {
    return api.Axios.Delete(url, id);
  },
  ListarComExcessao(params) {
    return api.Axios.GetParams(url, params);
  },
  ListarGrupos() {
    return api.Axios.Get(url);
  },
  SalvarGruposUsuario(dto) {
    return api.Axios.PostForm(url, dto);
  }
});
