import api from './utilBackoffice';

const controller = process.env.VUE_APP_AUTH_API + 'BackOfficeUsuario';
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar(dto) {
    return api.Axios.GetParams(controller, dto);
  },
  LocalizarRegistro(id) {
    return api.Axios.Get(controller, id);
  },
  Post(dto) {
    return api.Axios.Post(controller, dto);
  },
  Put(id, dto) {
    return api.Axios.Put(controller, id, dto);
  },
  Delete(id) {
    return api.Axios.Delete(controller, id);
  },
  ListarComExcessao(params) {
    return api.Axios.Get(controller, params);
  },
  ListarGrupoUsuario(id) {
    return api.Axios.Get(controller, id);
  },
  SalvarGruposUsuario(dto) {
    return api.Axios.Post(controller, dto);
  }
});
