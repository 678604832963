import api from './utilBackoffice';

const controller = 'ListRestrCadoc';
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => api.Axios.GetParams(url, dto),
  Create: dto => api.Axios.Post(url, dto),
  Update: (codigo, dto) => api.Axios.Put(url, codigo, dto),
  Delete: id => api.Axios.Delete(url, id)
});
