import api from './utilBackoffice';

const controller = 'Pessoa';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: function (dto) {
    return api.Axios.GetParams(url, dto);
  },
  GetByCodigo: function (dto) {
    return api.Axios.GetParams(`${url}/${dto.Codigo}`);
  },
  ListarParceirosUnidade: () => {
    return api.Axios.GetParams(url + '/ListarParceirosUnidade');
  },
  ListarPessoaFundo: function (dto) {
    return api.Axios.GetParams(url + '/ListarPessoaFundo', dto);
  },
  ListarPessoaJuridica: function (dto) {
    return api.Axios.GetParams(url + '/ListarPessoaJuridica', dto);
  },
  Create: function (dto) {
    return api.Axios.Post(url, dto);
  },
  Update: function (codigo, dto) {
    return api.Axios.Put(url, codigo, dto);
  },
  UpdatePessoaInfoExternas: function (codigo, dto) {
    return api.Axios.Put(url + '/UpdatePessoaInfoExternas', codigo, dto);
  }
});
