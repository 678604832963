import api from './utilBackoffice';

const controller = 'Proposta';
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => api.Axios.GetParams(url, dto),
  ListarCanceladas: dto => api.Axios.GetParams(url + '/Canceladas', dto),
  ListarPendentePagamento: dto =>
    api.Axios.GetParams(url + '/PendentePagamento', dto)
});
