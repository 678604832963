import api from '../utilBackoffice';
import axios from 'axios';

const controller = 'BackOffice';
const url = process.env.VUE_APP_AUTH_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  ListarBackOffice(token) {
    return axios
      .get(url + '/Listar', {
        headers: {
          authorization: `Bearer ${token}`
        }
      })
      .then(api.HandleResponseBase, api.HandleErrorBase);
  }
});
