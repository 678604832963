import api from './utilBackoffice';

const controller = 'SaldoContrato';
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({}, api.GetCrud(controller, null), {
  ListarSintetico: dto => {
    return api.Axios.GetParams(url + '/ListarSintetico', dto);
  },
  ListarAnalitico: dto => {
    return api.Axios.GetParams(url + '/ListarAnalitico', dto);
  }
});
