import api from './utilBackoffice';

const controller = 'ControleRedeLoja';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Post: dto => {
    return api.Axios.Post(url, dto);
  },
  Delete: dto => {
    return api.Axios.Post(url + '/Delete', dto);
  }
});
