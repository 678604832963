import api from './utilBackoffice';

const controller = 'EventoContabil';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => {
    return api.Axios.GetParams(url + '/Listar', dto);
  },
  ListarEventosContabeis: dto => {
    return api.Axios.GetParams(url + '/ListarEventosContabeis', dto);
  },
  ListarHistoricosByEvento: dto => {
    return api.Axios.GetParams(url + '/ListarHistoricosByEvento', dto);
  },
  ListarHistoricoByCodigo: codigo => {
    return api.Axios.GetParams(url + '/ListarHistoricoByCodigo', codigo);
  },
  ListarByEventoHistorico: dto => {
    return api.Axios.GetParams(url + '/ListarByEventoHistorico', dto);
  },
  ListarEventoContabilExistente: dto => {
    return api.Axios.GetParams(url + '/EventoContabilExistente', dto);
  },
  GetEventosContabeisByGrupoProduto: dto => {
    return api.Axios.GetParams(url + '/GetEventosContabeisByGrupoProduto', dto);
  },
  BuscaContas: dto => {
    return api.Axios.GetParams(url + '/BuscaContas', dto);
  },
  Create: dto => {
    return api.Axios.Post(url, dto);
  },
  Update: dto => {
    return api.Axios.Post(url + '/Update', dto);
  },
  Delete: codigo => {
    return api.Axios.Delete(url, codigo);
  },
  ListarCadastrados: dto => {
    return api.Axios.GetParams(url + '/ListarCadastrados', dto);
  }
});
