import api from './utilBackoffice';

const controller = 'Operador';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => {
    return api.Axios.GetParams(url, dto);
  },
  Create: dto => {
    return api.Axios.Post(url, dto);
  },
  DefineOperador: dto => {
    return api.Axios.Post(url + '/DefineEmail', dto);
  },
  Put: (codigo, dto) => {
    return api.Axios.Put(url, codigo, dto);
  }
});
