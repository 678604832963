import api from '../utilBackoffice';

const controller = 'Autenticacao';
const url = process.env.VUE_APP_AUTH_API + controller;

export default Object.assign({}, api.GetCrud(controller, null), {
  Login(dto) {
    return api.Axios.Post(url + '/Login', dto, null, '');
  },
  RefreshBackOffice(dto, token) {
    return api.Axios.Post(url + '/RefreshBackOffice', dto, null, token);
  },
  Logout() {
    return new Promise(resolve => {
      resolve('');
    });
  },
  RefreshToken() {
    const { refreshToken, token } = JSON.parse(localStorage.getItem('user'));

    const jwtData = token.split('.')[1];
    const decodedJwtJsonData = window.atob(jwtData);
    const epCodigo = JSON.parse(decodedJwtJsonData)['BmpDigital.EPCodigo'];

    const dto = {
      token: refreshToken,
      epCodigo: epCodigo
    };
    return this.RefreshBackOffice(dto, token).then(data => {
      return { ...data, expires: data.expiresIn };
    });
  },
  ResetSenha(dto) {
    const headers = null;
    const token = '';
    return api.Axios.Post(url + '/ResetSenha', dto, headers, token);
  },
  ValidarTokenResetSenha(dto) {
    const headers = null;
    const token = '';
    return api.Axios.Post(url + '/ValidarTokenResetSenha', dto, headers, token);
  },
  SolicitarTokenResetSenha(dto) {
    dto.isPartner = false;
    const headers = null;
    const token = '';
    return api.Axios.Post(
      url + '/SolicitarTokenResetSenha',
      dto,
      headers,
      token
    );
  }
});
