import api from './utilBackoffice';

const controller = 'CEPRisco';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => {
    return api.Axios.GetParams(url, dto);
  },
  Post: dto => {
    return api.Axios.Post(url, dto);
  },
  Delete: id => {
    return api.Axios.Delete(url, id);
  }
});
