import api from './utilBackoffice';

const controller = 'PessoaPF';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  GetByCodigoPessoa: dto => {
    return api.Axios.GetParams(`${url}/${dto.CodigoPessoa}`);
  },
  RendaTotal: dto => {
    return api.Axios.GetParams(url + '/RendaTotal', dto);
  },
  UpdateOutrasRendas: function (codigo, dto) {
    return api.Axios.Put(url + '/UpdateOutrasRendas', codigo, dto);
  },
  Update: function (codigoPessoa, dto) {
    return api.Axios.Put(url, codigoPessoa, dto);
  }
});
