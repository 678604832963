import api from './utilBackoffice';

const controller = 'GrupoProduto';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => {
    return api.Axios.GetParams(url + '/GetByTipoContrato', dto);
  },
  Create: dto => {
    return api.Axios.Post(url, dto);
  },
  Update: (codigo, dto) => {
    return api.Axios.Put(url, codigo, dto);
  },
  Delete: id => {
    return api.Axios.Delete(url, id);
  }
});
