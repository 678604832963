import api from '../utilBackoffice';

const controller = 'RemessaArquivo';
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => {
    return api.Axios.GetParams(url, dto);
  },
  GeraXML: dto => {
    return api.Axios.GetParams(url + '/GetArquivoXML', dto);
  },
  RegerarArquivo: dto => {
    return api.Axios.Post(url + '/RegerarArquivo', dto);
  }
});
